;((window) => {
    'use strict';
    let sfInitializationStarted = false;
    const INITIALIZED_STATE = {
        success: 1,
        skip: 2
    };

    const initializeSfChat = async (launchPoint) => {
        if (uitk.adapt.webView) return;
        const isIframe = window.self !== window.top;
        if (isIframe) return;

        if (sfInitializationStarted) {
            return INITIALIZED_STATE.skip;
        }

        const chatBotSessionEnabled = !!sessionStorage.getItem('sfChatBot.enabled');
        if (!chatBotSessionEnabled) return;

        const pageName = encodeURIComponent(EG?.pageName);
        const applicationName = encodeURIComponent(EG?.appName);
        if(!pageName && !applicationName) return;

        // Prevents chat loading on login page
        if (pageName === 'page.Auth') return;

        try {
            sfInitializationStarted = true;
            const payloadResponse = await fetch(`/conversation-service/v1/salesforce-settings?application_name=Webchat`, {
                credentials: 'same-origin'
            });

            if (!payloadResponse.ok) {
                throw new Error(`Conversation service fetch failed for SF Data: ${payloadResponse.status}`);
            }

            const payload = await payloadResponse.json();
            if (payload?.enabled) {
                await loadSfScript(payload?.esd_settings.script_src);
                await initEmbeddedMessaging(payload);
                await waitForEmbeddedMessagingReady(payload);

                if (payload.esd_settings.user_verification_enabled) {
                    addEmbeddedMessagingIdentityTokenExpiredEventListener(payload);
                }

                return INITIALIZED_STATE.success;
            } else {
                return INITIALIZED_STATE.skip;
            }
        } catch (err) {
            sfInitializationStarted = false;
            uitk.logger.error('UITK: SF chatBot.initializeSfChat failed, ', err);
            return INITIALIZED_STATE.skip;
        }
    };

    const loadSfScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = src;
            script.onload = () => {
                resolve();
            };
            script.onerror = (e) => {
                reject(new Error(`Failed to load SF chat script: ${e.target.src}`));
            };
            document.body.appendChild(script);
        });
    };

    const waitForEmbeddedMessagingReady = (payload) => {
        return new Promise((resolve) => {
            window.addEventListener("onEmbeddedMessagingReady", () => {
                window.sfChat = embeddedservice_bootstrap;
                embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(payload.prechat_fields);

                if (payload.esd_settings.user_verification_enabled) {
                    embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
                        identityTokenType: "JWT",
                        identityToken: payload.token
                    });
                }

                resolve();
            });
        });
    };

    const addEmbeddedMessagingIdentityTokenExpiredEventListener = (payload) => {
            window.addEventListener("onEmbeddedMessagingIdentityTokenExpired", async () => {
                const payloadResponse = await fetch(`/conversation-service/v1/jwt`, {
                    credentials: 'same-origin'
                });

                if (!payloadResponse.ok) {
                    throw new Error(`Conversation service jwt fetch failed: ${payloadResponse.status}`);
                }

                embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
                    identityTokenType: "JWT",
                    identityToken: payload.token
                });
            });
    };

    const initEmbeddedMessaging = async(payload) => {
        try {
           if (embeddedservice_bootstrap) {
               embeddedservice_bootstrap.settings.language = getEsdLanguage(); // e.g. the value can be 'en_US', etc
               embeddedservice_bootstrap.settings.hideChatButtonOnLoad = true;
               embeddedservice_bootstrap.settings.omitSandbox = true;
               await embeddedservice_bootstrap.init(
                    payload.esd_settings.id,
                    payload.esd_settings.esd_id,
                    payload.esd_settings.esd_endpoint,
                    {
                        scrt2URL: payload.esd_settings.scrt_url
                    }
                );
           }
        } catch(err) {
            throw new Error('Error loading Embedded Messaging: ' + err);
        }
    }

    const openSfChat = (fromPanel = false, launchPoint = "") => {
        sessionStorage.setItem('sfChatBot.enabled', 'true');
        try {
            if (window.sfChat) {
                uitk.helpPanel.panelClose();
                embeddedservice_bootstrap.utilAPI.launchChat().catch((err) => {
                    throw new Error('Failed to launch SF chat from opening, ' + err)
                });
            } else {
                uitk.sfChatBot.initializeSfChat(launchPoint).then(sfResponse => {
                    if (sfResponse === INITIALIZED_STATE.success) {
                        uitk.helpPanel.panelClose();
                        embeddedservice_bootstrap.utilAPI.launchChat().catch((err) => {
                            throw new Error('Failed to launch sf chat after initializing, ' + err)
                        });
                    }

                    if (!sfResponse) {
                        throw new Error('Failed to initialize sf chat');
                    }

                    const helpPanelIframe = document.querySelector('.contextual-help-content')

                    if (helpPanelIframe && fromPanel) {
                        if (!sfResponse) {
                            helpPanelIframe.contentWindow.postMessage("SF_CHAT_LOAD_FAILED");
                        }
                        helpPanelIframe.contentWindow.postMessage("SF_CHAT_PROCESSED");
                    } else {
                        if (!sfResponse) {
                            window.postMessage("SF_CHAT_LOAD_FAILED");
                        }
                        window.postMessage("SF_CHAT_PROCESSED");
                    }
                }).catch((err) => {
                    throw new Error('SF chat initialize and launch failed, ' + err)
                })
            }
        } catch (err) {
            uitk.logger.error('UITK: sfChatBot.openSfChat failed, ', err);
        }
    };

    // SF sometimes fails to convert some platform languages, therefore only use full support or end user languages
    // Reference: https://help.salesforce.com/s/articleView?id=sf.faq_getstart_what_languages_does.htm&type=5
    const sfSupportedLocales = ['zh_CN', 'zh_TW', 'nl_NL', 'en_US', 'pt_BR', 'es_MX', 'en_GB', 'pt_PT'];
    const sfSupportedLanguages = ['da', 'fi', 'fr', 'de', 'it', 'ja', 'ko', 'no', 'ru', 'es', 'sv', 'th',
        'ar', 'bg', 'hr', 'cs', 'el', 'iw', 'hu', 'in', 'pl', 'ro', 'sk', 'sl', 'tr', 'uk', 'vi'];

    const getEsdLanguage = () => {
        if (!EG || !EG.currentLocale) return 'en_GB';

        let egLocale = EG.currentLocale;

        let lan = egLocale.replace('-', '_');

        if (sfSupportedLocales.includes(lan)) return lan;

        lan = lan.split('_')[0];

        // Return if supported
        if (sfSupportedLanguages.includes(lan) || sfSupportedLocales.includes(lan)) return lan;

        // SF uses 'no' as Norwegian while we use 'nb'
        if ('nb' === lan) return 'no';

        // Otherwise default to sf supported
        if ('zh' === lan) return 'zh_CN';
        if ('nl' === lan) return 'nl_NL';
        if ('en' === lan) return 'en_GB';
        if ('pt' === lan) return 'pt_PT';

        // Default to en_GB eventually
        return 'en_GB';
    }

    const clearSfChat = () => {
        if (window.sfChat) {
            embeddedservice_bootstrap.userVerificationAPI.clearSession();
        }
    }

    // Add event listener for maximizing chat window
    window.addEventListener("onEmbeddedMessagingWindowMaximized", () => {
        const pageName = encodeURIComponent(EG?.pageName);
        uitk.analytics.track('a', {
            linkId: pageName + '.Chatbot.Floating.Clicked'
        })
    });

    uitk.sfChatBot = {
        initializeSfChat,
        openSfChat,
        clearSfChat
    };

    uitk.sfChatBot.initializeSfChat().catch((err) => {
        uitk.logger.error('UITK: SF chatBot.initialize process failed', err);
    });

})(window);